import React from "react";
import pdf from './BreatheEVUserGuide.pdf';

const PDFViewer: React.FC = () => {
    return (
        <div>
            <iframe
                src={pdf}
                title="PDF Viewer"
                style={{ border: "none" , height: '100vh', width:'100%'}}
            />
        </div>
    );
};

export default PDFViewer;
