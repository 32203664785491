import React, { useEffect, useState } from 'react';
import './Post.css';
import { Link, useParams } from 'react-router-dom';
import { findArticleBySlug } from '../articles';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { IoShare } from 'react-icons/io5';
import { AiFillThunderbolt } from 'react-icons/ai';


const Post: React.FC = () => {

  interface Article {
    id: number;
    author: string;
    title: string;
    slug: string;
    date: string;
    content: string;
    link: string;
    image: string;
    data: string;
}


  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopying, setIsCopying] = useState(false);

  // Fetch the blog post by slug
  useEffect(() => {
    const fetchBlogPost = async () => {
      if (slug) {
        const article = await findArticleBySlug(slug);
        setBlogPost(article || null);
      }
      setIsLoading(false);
    };

    fetchBlogPost();
  }, [slug]);

  // Update the document title when the blog post is loaded
  useEffect(() => {
    if (blogPost) {
      document.title = `${blogPost.title} - Blog | BreatheEV`;
    }
  }, [blogPost]);

  // Scroll to top helper function
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  // Handle share button click
  const handleShareClick = async () => {
    setIsCopying(true);
    const currentURL = window.location.href;
    await navigator.clipboard.writeText(currentURL);
    setTimeout(() => {
      setIsCopying(false);
    }, 2000);
  };

  if (isLoading) {
    return (
      <main id="main">
        <section id="post-main" className='loading'>
          <div className="container text-center">
            <div className='loading'>
                <AiFillThunderbolt />
            </div>
          </div>
        </section>
      </main>
    );
  }

  if (!blogPost) {
    return (
      <main id="main">
        <section id="post-main">
          <div className="container text-center">
            <h1>Blog Post Not Found</h1>
            <p>The blog post you're looking for could not be found.</p>
            <Link onClick={scrollToTop} to="/blog" className="btn btn-primary">
              Back to Blog
            </Link>
          </div>
        </section>
      </main>
    );
  }

  // Render the blog post
  return (
    <main id="main">
      <section id="post-main">
        <div className="container text-center">
          <h1>Blog</h1>
        </div>
      </section>

      <section id="post-row" className="container mt-3 bg-light mb-5">
        <div className="container-fluid">
          <div className="breadcrumbs">
            <Link onClick={scrollToTop} to="/blog">
              Blog
            </Link>{' '}
            <FaLongArrowAltRight /> {blogPost.title}
          </div>
          <div className="row">
            <h3 className="post-title">{blogPost.title}</h3>
            <div className="post-details">
              <p>Author: {blogPost.author}</p>
              <p>Published On: {blogPost.date}</p>
              <button className="share-button" onClick={handleShareClick} type="button">
                {isCopying ? 'Copied!' : 'Share'}
                <IoShare />
              </button>
            </div>
            <div
              className="post-content"
              dangerouslySetInnerHTML={{ __html: blogPost.data || '' }}
            ></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Post;
