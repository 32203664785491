import LogoImageMobile from '../Images/logo-mobile.png';

interface Article {
    id: number;
    author: string;
    title: string;
    slug: string;
    date: string;
    content: string;
    link: string;
    image: string;
    data: string;
}

let articles: Article[] = []; // Initially, the articles array is empty.

const fetchArticles = async (): Promise<Article[]> => {
    try {
        const response = await fetch('https://s3.us-west-2.amazonaws.com/app.breathe-ev/Blogs/breathe_blog.json');
        
        if (!response.ok) {
            throw new Error('Failed to fetch articles');
        }

        const rawData = await response.text();
        let data;
        try {
            data = JSON.parse(rawData).articles;
        } catch (e) {
            console.error('Invalid JSON response:', e);
            return [];
        }

        if (Array.isArray(data)) {
            return data.map((article: any) => ({
                ...article,
                image: LogoImageMobile, // Add default image
            }));
        } else {
            console.error('Fetched data is not an array:', data);
            return [];
        }
    } catch (error) {
        console.error('Error fetching articles:', error);
        return [];
    }
};

// Load articles into memory if not already loaded
const loadArticles = async (): Promise<void> => {
    if (articles.length === 0) {
        articles = await fetchArticles(); // Fetch and update the global `articles`
    }
};

// Async version of `getArticles`
export const getArticles = async (): Promise<Article[]> => {
    await loadArticles(); // Ensure articles are loaded before returning
    return articles;
};

// Function to find an article by its slug
export const findArticleBySlug = async (slug: string): Promise<Article | undefined> => {
    await loadArticles(); // Ensure articles are loaded before searching
    return articles.find(article => article.slug === slug);
};
