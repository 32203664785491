import React, { useState } from 'react';
import './Header.css';
import LogoImage from '../../../Images/logo.png';
import LogoImageMobile from '../../../Images/logo-mobile.png';
import Image from 'react-bootstrap/Image';
import { TiThMenu } from 'react-icons/ti';
import { IoMdClose } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { VscThreeBars } from "react-icons/vsc";




import { NavLink, useLocation } from 'react-router-dom';
import { FaEquals } from 'react-icons/fa6';
import { MdMore } from 'react-icons/md';

const Header: React.FC = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [productMenuOpen, setProductMenuOpen] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const scrollToTop = () => {
    setIsMobileNavOpen(false);
    setProductMenuOpen(false);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 200);
  };

  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <NavLink className="nav-link" to="/" onClick={scrollToTop}>
            <Image className='logo-large-device' src={LogoImage} alt="Logo" />
            <Image className='logo-small-device' src={LogoImageMobile} alt="Logo" />
          </NavLink>
        </h1>

        <nav id="navbar" className={`navbar order-last order-lg-0 ${isMobileNavOpen ? 'navbar-mobile' : ''}`}>
          <ul>
            <li>
              <NavLink className="nav-link" to="/" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                Home
              </NavLink>
            </li>
            <li className={`dropdown ${productMenuOpen ? 'open' : ''}`} >
              <button onClick={() => { setProductMenuOpen(productMenuOpen ? false : true) }} className={`nav-link ${isActive('/product') ? 'active' : ''}`} >
                Product <IoMdArrowDropdown />
              </button>
              <ul>
                <li>
                  <NavLink className="nav-link" to="/product/multifamily-ev-charging" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    Multifamily EV Charging
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/product/university-ev-charging" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    University EV Charging
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/product/commercial-ev-charging" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    Commercial EV Charging
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/product/ocpp-management-software" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    BreatheEV Software
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/product/purchase" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    Purchase
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/product/BERT" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    BERT
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink className="nav-link" to="/about" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                About
              </NavLink>
            </li>

            <li className='show-on-mobile-view'>
              <NavLink className="nav-link" to="/recognition" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                Recognition
              </NavLink>
            </li>
            <li className='show-on-mobile-view'>
              <NavLink className="nav-link" to="/blog" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                Blog
              </NavLink>
            </li>
            <li className='show-on-mobile-view'>
              <NavLink className="nav-link" to="/faq" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                FAQ
              </NavLink>
            </li>
            <hr style={{color: '#18365e'}} />
            <li className='show-on-mobile-view'>
              <NavLink className="nav-link" to="https://app.breathe-ev.com/login" target='_blank'>
                User Login
              </NavLink>
            </li>

            <li className='btn-install-chargers hide-on-mobile-view'>
              <NavLink to="/partner-with-us" className="partner-with-us-btn scrollto" onClick={scrollToTop}>
                Install Chargers<span className="d-none d-md-inline"></span>
              </NavLink>
            </li>


            <li className={`dropdown hide-on-mobile-view ${moreOpen ? 'open' : ''}`} >
              <button onClick={() => { setMoreOpen(moreOpen ? false : true) }}
                className={`nav-link more-btn ${(isActive('/recognition') || isActive('/blog') || isActive('/faq')) ? 'active' : ''}`} >
                <VscThreeBars />
              </button>
              <ul className='right-menu'>

                <li>
                  <NavLink className="nav-link" to="/recognition" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    Recognition
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/blog" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    Blog
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="/faq" onClick={() => { setIsMobileNavOpen(false); scrollToTop(); }}>
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink className="nav-link" to="https://app.breathe-ev.com/login" target='_blank'>
                    User Login
                  </NavLink>
                </li>
              </ul>
            </li>



          </ul>
          {isMobileNavOpen && <IoMdClose className={'bi mobile-nav-toggle bi-x'} onClick={toggleMobileNav} />}
          {!isMobileNavOpen && <TiThMenu className={'mobile-nav-toggle'} onClick={toggleMobileNav} />}
        </nav>
        <NavLink to="/partner-with-us" className="partner-with-us-btn scrollto show-on-mobile-view" onClick={scrollToTop}>
          Install Chargers<span className="d-none d-md-inline"></span>
        </NavLink>

      </div>
    </header>
  );
};

export default Header;
